import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { CheckCircle, CheckCircleOutlineOutlined } from "@material-ui/icons";
import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import "./Resources.css";
import { LanguageContext } from "../../contexts/language";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "384px",
    maxWidth: "384px",
    alignItems: "center",
    backgroundColor: "#D9D9D9",
    marginRight: "0",
  },
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  subTitle: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: ".8rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  listText: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.3rem",
    fontStyle: "normal",
  },
  media: {
    marginRight: "0",
    height: "384px",
    maxWidth: "384px",
    backgroundPosition: "inherit",
  },
  box: {
    backgroundColor: "#e2dcdc",
    border: 6,
    borderColor: "black",
    height: "5rem",
    //borderColor: "red",
  },
  forSaleButton: {
    color: "white",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "10rem",
    backgroundColor: "#144D73",
    borderRadius: "50px",
    fontSize: "11px",
  },
  borderLine: {
    minHeight: "3px",
    backgroundColor: "#144D73",
  },
  gridSpacing: {
    marginBottom: "80px",
  },
}));

const MyFormGroup = (item, index, t, handleChange, classes) => {
  return (
    <FormGroup style={{ fontFamily: "Roboto" }} row>
      <FormControlLabel
        style={{ padding: "10px" }}
        control={
          <Checkbox
            icon={<CheckCircleOutlineOutlined />}
            checked={item.checked}
            onChange={handleChange}
            checkedIcon={<CheckCircle />}
            name="checkedA"
          />
        }
        label={
          <Typography className={classes.listText}>
            {t(`ItemsHomeLoan.${index}`)}
          </Typography>
        }
      />
    </FormGroup>
  );
};

const MyFormGroupVet = (item, index, t, handleChange, classes) => {
  return (
    <FormGroup style={{ fontFamily: "Roboto" }} row>
      <FormControlLabel
        style={{ padding: "10px" }}
        control={
          <Checkbox
            icon={<CheckCircleOutlineOutlined />}
            checked={item.checked}
            onChange={handleChange}
            checkedIcon={<CheckCircle />}
            name="checkedA"
          />
        }
        label={
          <Typography className={classes.listText}>
            {t(`ItemsVetLoan.${index}`)}
          </Typography>
        }
      />
    </FormGroup>
  );
};

const Resources = () => {
  const classes = useStyles();
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;

  const [state, setState] = React.useState({
    itemsForHomeLoan: [
      { unchecked: false },
      { unchecked: false },
      { unchecked: false },
      { unchecked: false },
      { unchecked: false },
      { unchecked: false },
      { unchecked: false },
      {
        unchecked: false,
      },
    ],
    itemsForVetLoan: [{ unchecked: false }, { unchecked: false }],
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center", color: "#144D73" }}>
          {t("Resources.1")}
        </h1>
      </div>

      <Grid
        className={classes.gridContainer}
        container
        spacing={0}
        //direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid spacing={0} sx={12} md={12} align="center">
          <h4 style={{ color: "#144D73" }}>{t("ResourcesHeader.1")}</h4>
        </Grid>
        <Grid spacing={0} sx={12} md={6} align="center">
          <div className="box">
            <div className={classes.subTitle}>
              <h1 style={{ textAlign: "center" }}>
                {t("ItemsHomeLoanTitle.1")}
              </h1>
            </div>
            <div className={classes.borderLine}></div>
            <Box>
              {state.itemsForHomeLoan.map((item, index) =>
                MyFormGroup(item, index, t, handleChange, classes)
              )}
            </Box>
          </div>
        </Grid>
        <Grid spacing={0} sx={12} md={6} align="center">
          <div className="box">
            <div className={classes.subTitle}>
              <h1 style={{ textAlign: "center" }}>{t("VALoanTitle.1")}</h1>
            </div>
            <div className={classes.borderLine}></div>
            <Box>
              {state.itemsForVetLoan.map((item, index) =>
                MyFormGroupVet(item, index, t, handleChange, classes)
              )}
            </Box>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Resources;
