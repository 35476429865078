import { createTheme } from "@material-ui/core/styles";

//const nebBlue = "#5e837f";
const primaryWhite = "#FFFFFF";
//#1d1c1c
const secondaryBlue = "#144D73";
const nebGreen = "#07b8b8";
const nebDarkBlue = "#1A3352";
//const underCarriage = "#01aee3";
//#0e2c58
//#1d1c1c
const theme = createTheme({
  palette: {
    common: {
      primaryWhite: `${primaryWhite}`,
      secondaryBlue: `${secondaryBlue}`,
      blue: `${secondaryBlue}`,
      nebGreen: `${nebGreen}`,
      nebDarkBlue: `${nebDarkBlue}`,
    },
    primary: {
      main: `${primaryWhite}`,
    },
    secondary: {
      main: `${secondaryBlue}`,
    },
  },
  typography: {
    h3: {
      fontWeight: 700,
      fontFamily: "Roboto",
    },
    tab: {
      color: "blue",
      font: "cursive",
    },
    body2: {
      color: "red",
    },
  },
});

export default theme;
