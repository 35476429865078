import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext("en");

const LanguageContextProvider = (props) => {
  const [lang, setLang] = useState("en");
  const { t, i18n } = useTranslation();

  const setTranslation = (lang) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={[{ t: t, lang: lang }, setTranslation]}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
