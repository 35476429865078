import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { LanguageContext } from "../../contexts/language";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  speechBubble: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      width: "400px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      marginBottom: "50px",
      padding: 0,
      width: "90%",
    },
    width: "600px",
    margin: "50px auto",
    background: "#bfa28896",
    padding: "20px",
    textAlign: "center",
    fontWeight: 900,
    color: "#144D73",
    fontFamily: "arial",
    position: "relative",
  },
  sb4: {
    "&:before": {
      content: "' '",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid #bfa28896",
      borderTop: "10px solid #bfa28896",
      borderBottom: "10px solid transparent",
      right: "19px",
      bottom: "-19px",
    },
  },
}));

const Testimonials = () => {
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center" }}>{t("Testimonials.1")}</h1>
      </div>
      <Grid
        container
        spacing={0}
        className={classes.gridContainer}
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Diana is a one of a kind realtor that goes above and beyond for her
            clients. We were relocating from OR. To TX and we had her jumping
            all over El Paso. From one house to the another we did videos
            working with our local family viewing houses. She even let us know
            about the neighborhoods and schools. She was perfect for the job,
            nit picking houses so we didn’t buy something that we would regret.
            We were very impressed with her and wondered how she got any sleep
            as we would call her late at night. We realized we had the right
            Realtor working for us. We couldn’t have done it with out her help,
            just an absolute realtor that protects her clients best interest. "
            <div>-K.Ames </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Working with Diana as your realtor means honesty, transparency, and
            trust. She does what is in the clients best interest and is always
            on your side. Our home buying process in 2022 would not have been as
            smooth and easy without her and we could not recommend her enough! "
            <div>-E.Cano</div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            " This is the best Realtor you can have. Diana went out of her way
            to satisfy her customer. Diana sold me a brand new house and also
            was able to sell my old house. I would definitely recommend her.
            ———Also, a friend, a good hearted person u can trust. "
            <div>-Jennifer Gabaldon</div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Our experience with Diana Torres-McCann as our realtor has been
            beyond amazing. Beginning as newly-weds with Diana being honest,
            letting us know when it was the best time for us to begin the
            process of purchasing our first home, and guiding us on how to
            begin. Thanks to Diana’s advice, knowledge, time and patience
            (because we had many questions). Diana's guidance made it possible
            for us to purchase our home sooner than we thought and we were well
            prepared for it. We have purchased two homes now through Diana and
            we are so very happy with both experiences. Thanks to Diana, we were
            able to purchase our second home during the crazy real estate market
            peak when houses were being sold before you could even see them. It
            was the one we fell in love with, and in the area we wanted. Diana
            is the most trustworthy, honest, knowledgeable, and dedicated
            Realtor we have ever met and worked with. She is also a joy to be
            around! Diana will forever be our realtor and I cannot see
            purchasing a property through anyone else. You cannot go wrong with
            Diana. Simply the best!"
            <div>-Abel and Mayra Bustillos</div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Diana has been our realtor for several years, and we have always
            had a positive experience! You can count on her to walk you through
            all the steps in purchasing or selling a home. Diana is honest and
            definitely has your best interest in mind. She is responsive on all
            communication and follows up promptly on questions. We would not
            think twice about working with Diana"
            <div>-C.M. and T.H.</div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Diana McCann has been a great realtor since l have been doing
            business with her, she has provided us with unique information about
            anything you want to know about how to buy a property (house) and l
            recommend her 100% for any real state assistance, she is always
            willing to help you and assist you all the time. She is one of the
            best realtor in El Paso, Texas."
            <div>-Luis Pizana Jr.</div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} sm={12} lg={4}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Si andan buscando una buena real estate para comprar o vender su
            casa en El Paso Tex, Recomiendo altamente a Diana Torres, ella es la
            mejor en El Paso Tex, ella consigue los mejores precios y trabaja
            duro siempre a favor de sus clientes, tiene mucha experiencia en
            Real Estate, nosotros hemos necesitado sus servicios tres veces y
            hemos quedado súper contentos con su trabajo, su trabajo siempre es
            de calidad y ella es una persona súper amable, responsable y con un
            enorme corazón, no busquen más !!… vallan con Diana Torres, estoy
            segura q les ayudará a comprar la casa de sus sueños!!… gracias
            Diana por seguir siendo nuestro agente 💝"
            <div>-Lorenia Pizana..</div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Testimonials;
