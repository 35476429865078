import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { LanguageContext } from "../../contexts/language";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "4.7rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "3.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3.2rem",
    },
  },
  logo: {
    height: "6rem",
    paddingLeft: "3rem",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    [theme.breakpoints.down("md")]: {
      height: "5rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4rem",
    },
  },
  customToolbar: {
    minHeight: "6rem",
  },
  tabContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
    },
    ...theme.typography,
  },
  tab: {
    //need width and min width both implement-high-fidelity-designs-with-material-ui-and-reactjs/learn/lecture/16284888#questions/10616142
    width: "10rem",
    minWidth: ".5rem",
    color: "#144D73",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "19px",
  },
  button: {
    //marginLeft: "100px",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "400px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "150px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "100px",
    },
    borderRadius: "50px", //make a button rounded
    fontFamily: "Pacifico",
    textTransform: "none",
    height: "50px",
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "50px",
    width: "50px",
    color: "#073c54",
  },
  drawer: {
    backgroundColor: theme.palette.common.blue,
    width: "50%",
  },
  drawerItem: {
    color: "white",
  },
  drawerContactMe: {
    backgroundColor: "#5e837f",
    fontFamily: "Pacifico",
  },
  drawerItemSelected: {
    opacity: 1,
    color: "grey",
  },
}));

const Header = (props) => {
  const [translationState, setTranslation] = useContext(LanguageContext);
  const { t, lang } = translationState;

  function handleLanguageChange(lang) {
    if (lang === "en") {
      setTranslation("es");
    } else if (lang === "es") {
      setTranslation("en");
    }
  }

  function getLangButtonText(lang) {
    if (lang === "en") {
      return "En Español";
    } else {
      return "In English";
    }
  }

  const theme = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (window.location.pathname === "/" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/listings" && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === "/resources" && value !== 2) {
      setValue(2);
    } else if (window.location.pathname === "/testimonials" && value !== 3) {
      setValue(3);
    } else if (window.location.pathname === "/about" && value !== 4) {
      setValue(4);
    } else if (window.location.pathname === "/contact" && value !== 5) {
      setValue(5);
    }
  }, [value]);

  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabContainer}
        centered
        classes={{ indicator: classes.styledIndicator }}
      >
        <Tab
          className={classes.tab}
          label={t("Home.1")}
          component={Link}
          to="/"
        ></Tab>
        <Tab
          className={classes.tab}
          label={t("Listings.1")}
          component={Link}
          to="/listings"
        ></Tab>
        <Tab
          className={classes.tab}
          label={t("Resources.1")}
          component={Link}
          to="/resources"
        ></Tab>
        <Tab
          className={classes.tab}
          label={t("Testimonials.1")}
          component={Link}
          to="/testimonials"
        ></Tab>
        <Tab
          className={classes.tab}
          label={t("About.1")}
          component={Link}
          to="/about"
        ></Tab>
        <Tab
          className={classes.tab}
          label={t("Contact.1")}
          component={Link}
          to="/contact"
        ></Tab>
      </Tabs>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => {
          setOpenDrawer(false);
          handleLanguageChange(lang);
        }}
      >
        {getLangButtonText(lang)}
      </Button>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <List disablePadding>
          <ListItem
            selected={value === 0}
            onClick={() => {
              setOpenDrawer(false);
              setValue(0);
            }}
            divider
            button
            component={Link}
            to="/"
          >
            <ListItemText
              className={
                value === 0
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("Home.1")}
            </ListItemText>
          </ListItem>
          <ListItem
            selected={value === 1}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(1);
            }}
            divider
            button
            component={Link}
            to="/listings"
          >
            <ListItemText
              className={
                value === 1
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("Listings.1")}
            </ListItemText>
          </ListItem>
          <ListItem
            selected={value === 2}
            onClick={() => {
              setOpenDrawer(false);
              setValue(2);
            }}
            divider
            button
            component={Link}
            to="/resources"
          >
            <ListItemText
              className={
                value === 2
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("Resources.1")}
            </ListItemText>
          </ListItem>

          <ListItem
            selected={value === 3}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(3);
            }}
            divider
            button
            component={Link}
            to="/testimonials"
          >
            <ListItemText
              className={
                value === 3
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("Testimonials.1")}
            </ListItemText>
          </ListItem>

          <ListItem
            selected={value === 4}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(4);
            }}
            divider
            button
            component={Link}
            to="/about"
          >
            <ListItemText
              className={
                value === 4
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("About.1")}
            </ListItemText>
          </ListItem>

          <ListItem
            selected={value === 5}
            onClick={() => {
              setOpenDrawer(false);
              props.executeScroll();
              setValue(5);
            }}
            divider
            button
            component={Link}
            to="/contact"
          >
            <ListItemText
              className={
                value === 5
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {t("Contact.1")}
            </ListItemText>
          </ListItem>
          <ListItem
            className={classes.drawerContactMe}
            selected={value === 6}
            onClick={() => {
              handleLanguageChange(lang);
            }}
            divider
            button
          >
            <ListItemText
              className={
                value === 6
                  ? [classes.drawerItem, classes.drawerItemSelected]
                  : classes.drawerItem
              }
              disableTypography
            >
              {getLangButtonText(lang)}
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={classes.drawerIcon}></MenuIcon>
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <Toolbar className={classes.customToolbar} disableGutters>
            <Button
              disableRipple
              style={{ backgroundColor: "transparent" }}
              component={Link}
              to="/"
              onClick={handleChange}
            >
              <img
                alt="Diana Realtor Logo"
                className={classes.logo}
                src={logo}
              />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* This is used to have that gap in between the header and content below #20 styling inline 8:21 */}
      <div className={classes.toolbarMargin}></div>
    </React.Fragment>
  );
};

export default Header;
