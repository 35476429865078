import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, TextField } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { LanguageContext } from "../../contexts/language";
import validator from "validator";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formTitle: {
    paddingTop: "70px",
    textAlign: "center",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "3rem",
    // fontStyle: "capitalize",
    fontWeight: 900,
    textTransform: "capitalize",
  },
  formSubtitle: {
    paddingBottom: "55px",
    textAlign: "center",
    color: "#144D73",
    fontFamily: "Hoefler Text",
    fontSize: "1rem",
    fontWeight: 400,
    // fontStyle: "capitalize",
  },
  formButton: {
    color: "#FFFFFF",
    backgroundColor: "#144D73",
    borderRadius: "50px",
    marginLeft: "10px",
    // alignContent: "center",
    // alignItems: "center",
    // textAlign: "center",
  },
  text: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
  },
  inputField: {
    [theme.breakpoints.down("xs")]: { width: "90vw" },
    backgroundColor: "white",
    borderRadius: "10px",
    width: "40rem",
    alignItems: "center",
  },
  messageInputField: {
    [theme.breakpoints.down("xs")]: { width: "90vw" },
    backgroundColor: "white",
    borderRadius: "30px",
    width: "40rem",
    height: "150px",
  },
  contactForm: {
    [theme.breakpoints.down("xs")]: { width: "100%", height: "1200px" },
    [theme.breakpoints.down("lg")]: { width: "95%", height: "1000px" },
    [theme.breakpoints.down("md")]: { width: "100%", height: "900px" },
    width: "90vh",
    height: "70vh",
    color: "#144D73",
    background: "#B8CCD9",
    marginBottom: "50px",
  },
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  box: {
    [theme.breakpoints.down("lg")]: { minWidth: "10%" },
    [theme.breakpoints.down("xl")]: { minWidth: "50%" },
    [theme.breakpoints.down("md")]: { minWidth: "80%" },
    minWidth: "90%",
  },
  halfMoonTop: {
    top: "50%",
    left: "50%",
    textTransform: "center",
    //transform: "translate(-50%, -50%)",
    height: "100px",
    width: "200px",
    borderRadius: "150px 150px 0 0",
    backgroundColor: "#BFA28899",
    color: "#144D73",
    fontFamily: "Roboto",
  },
  halfMoonBottom: {
    top: "50%",
    left: "50%",
    //transform: "translate(-50%, -50%)",
    height: "100px",
    width: "200px",
    borderRadius: "0px 0px 150px 150px",
    backgroundColor: "#FFFFFF",
    outlineStyle: "auto",
    outlineColor: "#BFA288",
    color: "#144D73",
    fontFamily: "Roboto",
  },
  contactFormSubheaders: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      alignItems: "left !important",
      marginLeft: "5px",
    },
    //alignItems: "left !important",
    marginLeft: "210px",
    // justifyContent: "left !important",
    textAlign: "auto",
  },
}));

const Contact = () => {
  const [formState, setFormState] = React.useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validator.isEmail(formState.email)) {
      alert("email Is invalid");
      return;
    } else if (!validator.isMobilePhone(formState.phone)) {
      alert("Phone Is invalid");
      return;
    }

    try {
      const response = await axios.post(
        "https://xnbdaliix9.execute-api.us-east-1.amazonaws.com/prod/contact_me",
        formState,
        {
          mode: "no-cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert("Email Sent! I will reach out Shortly!");
    } catch (error) {
      console.log(error);
      alert("Email Not sent Succesfully. Please Try again Later");
    }

    document.getElementById("contact-form").reset();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("the name and value: ", name, value);
    setFormState({ ...formState, [name]: value });
  };

  const classes = useStyles();
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;
  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center", textTransform: "capitalize" }}>
          {t("Contact.1")}
        </h1>
      </div>

      <Grid
        className={classes.gridContainer}
        container
        spacing={0}
        //direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ paddingBottom: "100px" }}
      >
        <Grid item xs={12} md={4} align="center">
          <h3 className={classes.halfMoonTop}>
            {" "}
            <br></br>
            <MailOutlineIcon style={{ color: "white" }}></MailOutlineIcon>
            <br></br>EMAIL
          </h3>
          <h3 className={classes.halfMoonBottom}>
            {" "}
            <br></br>
            diana_tm@me.com
          </h3>
        </Grid>
        <Grid item xs={12} md={4} align="center">
          <h3 className={classes.halfMoonTop}>
            {" "}
            <br></br>
            <PhoneAndroidOutlinedIcon
              style={{ color: "white" }}
            ></PhoneAndroidOutlinedIcon>
            <br></br>
            {t("Phone.1")}
          </h3>
          <h3 className={classes.halfMoonBottom}>
            {" "}
            <br></br>(915) 276 - 9004
          </h3>
        </Grid>
        <Grid item xs={12} md={4} align="center">
          <h3 className={classes.halfMoonTop}>
            {" "}
            <br></br>
            <LocationOnOutlinedIcon
              style={{ color: "white" }}
            ></LocationOnOutlinedIcon>
            <br></br>
            {t("Address.1")}
          </h3>
          <h3 className={classes.halfMoonBottom}>
            <br></br>Vision Realty El Paso, TX
          </h3>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Box style={{ backgroundColor: "#B8CCD9" }} className={classes.box}>
          <div className={classes.formTitle}>{t("InTouch.1")}</div>
          <p className={classes.formSubtitle}>{t("NoSpam.1")}</p>
          <Grid item>
            <form
              id="contact-form"
              className={classes.contactForm}
              onSubmit={handleSubmit}
            >
              <h4 className={classes.contactFormSubheaders}> {t("Name.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="name"
                  label="Name"
                  name="userName"
                  className={classes.inputField}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}> {t("Email.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="email"
                  label="Email"
                  name="email"
                  className={classes.inputField}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}>{t("Phone.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="phone"
                  label="Phone"
                  name="phone"
                  className={classes.inputField}
                  style={{ width: "20rem !important" }}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}>
                {" "}
                {t("Message.1")}
              </h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="message"
                  label="Message"
                  name="message"
                  className={classes.messageInputField}
                  onChange={handleChange}
                  margin="normal"
                  multiline="true"
                  rowsMax="4"
                />
              </Grid>
              <Grid
                container
                direction="row"
                spacing={0}
                style={{ marginTop: 20 }}
              >
                <Grid
                  container
                  spacing={0}
                  // direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    className={classes.formButton}
                    type="reset"
                    variant="contained"
                    color="primary"
                    //disabled={submitting || pristine}
                  >
                    {t("Reset.1")}
                  </Button>

                  <Button
                    className={classes.formButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t("Send.1")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {/* </div> */}
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Contact;
