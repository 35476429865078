import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Card, CardMedia, Grid, Button } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import HomeBanner from "../../assets/home.png";
import data from "../Home/output.json";
import { LanguageContext } from "../../contexts/language";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "384px",
    maxWidth: "384px",
    alignItems: "center",
    backgroundColor: "#D9D9D9",
    marginRight: "0",
    marginBottom: "30px",
  },
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  media: {
    marginRight: "0",
    height: "384px",
    maxWidth: "384px",
    backgroundPosition: "inherit",
  },
  borderLine: {
    minHeight: "3px",
    backgroundColor: "#144D73",
  },
  forSaleButton: {
    color: "white",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "10rem",
    backgroundColor: "#144D73",
    borderRadius: "50px",
    fontSize: "11px",
  },
  gridSpacing: {
    marginBottom: "80px",
  },
}));

const Listings = () => {
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;
  const classes = useStyles();
  const MyListings = (item, classes) => {
    return (
      <Grid item xs={12} md={3} align="center">
        <Card className={classes.root}>
          <CardActionArea
            href={`https://www.realtor.com/realestateandhomes-detail/${item.permalink}`}
          >
            <CardMedia
              className={classes.media}
              image={item.primary_photo.href.replace(".jpg", "-w400.jpg")}
              title="Texas Homes"
            />
            <div className={classes.borderLine}></div>
          </CardActionArea>
          <CardContent>
            <Typography
              color="secondary"
              className={classes.text}
              gutterBottom
              variant="h6"
              //component="h2"
            >
              {item.description.sold_price
                ? item.description.sold_price
                : "Click for Price"}
              <Button
                style={{ backgroundColor: "#144D73" }}
                disableRipple
                className={classes.forSaleButton}
              >
                {item.status}
              </Button>
            </Typography>

            <Typography
              className={classes.body1}
              color="secondary"
              component="p"
            >
              {item.location.address.line}
            </Typography>
            <Typography
              className={classes.body2}
              color="secondary"
              component="p"
            >
              {item.location.address.city}, {item.location.address.state}{" "}
              {item.location.address.postal_code}
            </Typography>
            <Typography
              className={classes.body}
              color="secondary"
              component="p"
            >
              {item.description.beds} Beds | {item.description.baths} Baths |{" "}
              {item.description.sqft} Sq Ft
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center" }}>{t("Listings.1")}</h1>
      </div>

      <Grid
        className={classes.gridContainer}
        container
        spacing={0}
        //direction="column"
        alignItems="center"
        justify="center"
      >
        {data.previousListings.map((item) => MyListings(item, classes))}
      </Grid>
    </React.Fragment>
  );
};
export default Listings;
