import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import HomeBanner from "../../assets/home.png";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import validator from "validator";
import data from "./output.json";
import { LanguageContext } from "../../contexts/language";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "384px",
    maxWidth: "384px",
    alignItems: "center",
    backgroundColor: "#D9D9D9",
    marginRight: "0",
    marginBottom: "30px",
  },
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  formTitle: {
    paddingTop: "70px",
    textAlign: "center",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "3rem",
    // fontStyle: "capitalize",
    fontWeight: 900,
    textTransform: "capitalize",
  },
  formSubtitle: {
    paddingBottom: "55px",
    textAlign: "center",
    color: "#144D73",
    fontFamily: "Hoefler Text",
    fontSize: "1rem",
    fontWeight: 400,
    // fontStyle: "capitalize",
  },
  formButton: {
    color: "#FFFFFF",
    backgroundColor: "#144D73",
    borderRadius: "50px",
    marginLeft: "10px",
    // alignContent: "center",
    // alignItems: "center",
    // textAlign: "center",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: { paddingLeft: "0" },
    // paddingLeft: "2em",
    //paddingRight: "2em",
    paddingTop: ".5em",
    paddingBottom: "100px",
    // width: "100% !important",
    margin: "0 !important",
  },
  media: {
    marginRight: "0",
    height: "384px",
    maxWidth: "384px",
    backgroundPosition: "inherit",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#2d383185", //#00000085 not bad color
  },
  borderLine: {
    minHeight: "3px",
    backgroundColor: "#144D73",
  },
  forSaleButton: {
    color: "white",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "10rem",
    backgroundColor: "#144D73",
    borderRadius: "50px",
    fontSize: "11px",
  },
  topLine: {
    fontSize: "1.7rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
    height: ".5rem",
    paddingTop: "50px",
  },
  bottomLine: {
    fontSize: "2.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  body1: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
  },
  body2: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: "15px",
  },
  text: {
    color: "#144D73",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
  },
  inputField: {
    [theme.breakpoints.down("xs")]: { width: "90vw" },
    backgroundColor: "white",
    borderRadius: "10px",
    width: "40rem",
    alignItems: "center",
  },
  messageInputField: {
    [theme.breakpoints.down("xs")]: { width: "90vw" },
    backgroundColor: "white",
    borderRadius: "30px",
    width: "40rem",
    height: "150px",
  },
  box: {
    [theme.breakpoints.down("lg")]: { minWidth: "10%" },
    [theme.breakpoints.down("xl")]: { minWidth: "50%" },
    [theme.breakpoints.down("md")]: { minWidth: "80%" },
    minWidth: "90%",
  },
  contactForm: {
    [theme.breakpoints.down("xs")]: { width: "100%", height: "1200px" },
    [theme.breakpoints.down("lg")]: { width: "95%", height: "1000px" },
    [theme.breakpoints.down("md")]: { width: "100%", height: "900px" },
    width: "90vh",
    height: "70vh",
    color: "#144D73",
    background: "#B8CCD9",
    marginBottom: "50px",
  },
  contactFormSubheaders: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      alignItems: "left !important",
      marginLeft: "5px",
    },
    //alignItems: "left !important",
    marginLeft: "210px",
    // justifyContent: "left !important",
    textAlign: "auto",
  },
  carousel: {
    height: "200px",
    width: "90%",
  },
  speechBubble: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      width: "900px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      marginBottom: "50px",
      padding: 0,
      width: "100%",
    },
    width: "900px",
    margin: "50px auto",
    background: "#bfa28896",
    padding: "20px",
    textAlign: "center",
    fontWeight: 900,
    color: "#144D73",
    fontFamily: "arial",
    position: "relative",
  },
  sb4: {
    "&:before": {
      [theme.breakpoints.down("lg")]: {
        content: "' '",
        width: "0px",
        height: "0px",
        position: "absolute",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid #bfa28896",
        borderTop: "10px solid #bfa28896",
        borderBottom: "10px solid transparent",
        right: "19px",
        bottom: "-19px",
      },
      [theme.breakpoints.down("md")]: {
        content: "' '",
        width: "0px",
        height: "0px",
        position: "absolute",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid #bfa28896",
        borderTop: "10px solid #bfa28896",
        borderBottom: "10px solid transparent",
        right: "19px",
        bottom: "-19px",
      },
      [theme.breakpoints.down("xs")]: {
        content: "' '",
        width: "0px",
        height: "0px",
        position: "absolute",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid #bfa28896",
        borderTop: "10px solid #bfa28896",
        borderBottom: "10px solid transparent",
        right: "19px",
        bottom: "-19px",
      },
      content: "' '",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid #bfa28896",
      borderTop: "10px solid #bfa28896",
      borderBottom: "10px solid transparent",
      right: "19px",
      bottom: "-19px",
    },
  },
}));

// "location": {
//   "address": {
//       "line": "1374 Vista Granada Dr",
//       "city": "El Paso",
//       "state_code": "TX",
//       "state": "Texas",
//       "postal_code": "79936",
//       "country": null,
//       "coordinate": {
//           "lat": 31.735246,
//           "lon": -106.30775
//       }
//   }
// }

const showListings = (t, classes) => {
  return (
    <div className={classes.title}>
      <h1 style={{ textAlign: "center" }}>{t("Listings.1")}</h1>
    </div>
  );
};

const showPreviousListings = (t, classes) => {
  return (
    <div className={classes.title}>
      <h1 style={{ textAlign: "center" }}>{t("Listings.1")}</h1>
    </div>
  );
};
const MyActiveListings = (item, classes) => {
  return (
    <Grid item xs={12} md={4} align="center">
      <Card className={classes.root}>
        <CardActionArea
          href={`https://www.realtor.com/realestateandhomes-detail/${item.permalink}`}
        >
          <CardMedia
            className={classes.media}
            // image={.replace(".jpg","-w400.jpg")}
            image={item.primary_photo.href.replace(".jpg", "-w400.jpg")}
            title="Texas Home"
          />
          <div className={classes.borderLine}></div>
        </CardActionArea>
        <CardContent>
          <Typography
            color="secondary"
            className={classes.text}
            gutterBottom
            variant="h6"
            //component="h2"
          >
            {item.description.sold_price
              ? item.description.sold_price
              : "Click for Price"}
            <Button
              style={{ backgroundColor: "#144D73" }}
              disableRipple
              className={classes.forSaleButton}
            >
              {item.status}
            </Button>
          </Typography>

          <Typography className={classes.body1} color="secondary" component="p">
            {item.location.address.line}
          </Typography>
          <Typography className={classes.body2} color="secondary" component="p">
            {item.location.address.city}, {item.location.address.state}{" "}
            {item.location.address.postal_code}
          </Typography>
          <Typography className={classes.body} color="secondary" component="p">
            {item.description.beds} Beds | {item.description.baths} Baths |{" "}
            {item.description.sqft} Sq Ft
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Home = (props) => {
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;
  const classes = useStyles();
  const [state, setState] = React.useState({
    data: {
      activeListings: [],
      previousListings: [],
    },
  });

  const [formState, setFormState] = React.useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validator.isEmail(formState.email)) {
      alert("email Is invalid");
      return;
    } else if (!validator.isMobilePhone(formState.phone)) {
      alert("Phone Is invalid");
      return;
    }

    try {
      const response = await axios.post(
        "https://xnbdaliix9.execute-api.us-east-1.amazonaws.com/prod/contact_me",
        formState,
        {
          mode: "no-cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert("Email Sent! I will reach out Shortly!");
    } catch (error) {
      console.log(error);
      alert("Email Not sent Succesfully. Please Try again Later");
    }

    document.getElementById("contact-form").reset();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("the name and value: ", name, value);
    setFormState({ ...formState, [name]: value });
  };

  React.useEffect(() => {
    axios
      .get(
        `https://ji9chddtn3.execute-api.us-east-1.amazonaws.com/Prod/retrieve-listings`,
        {
          mode: "no-cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setState({ data: response.data });
      });
  }, []);

  const theme = useTheme();
  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center" }}>{t("Listings.1")}</h1>
      </div>
      {state.data.activeListings === [] ? showListings : showPreviousListings}

      <Grid
        className={classes.gridContainer}
        container
        spacing={0}
        //direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {state.data.previousListings
          .slice(0, 6)
          .map((item) => MyActiveListings(item, classes))}

        <Grid item xs={12} md={12}>
          <div className={classes.title}>
            <h1 style={{ textAlign: "center" }}>{t("Testimonials.1")}</h1>
          </div>
        </Grid>
        <Carousel className={classes.carousel}>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Working with Diana as your realtor means honesty, transparency, and
            trust. She does what is in the clients best interest and is always
            on your side. Our home buying process in 2022 would not have been as
            smooth and easy without her and we could not recommend her enough! "
            <div>-E.Cano</div>
          </div>

          <div className={clsx(classes.speechBubble, classes.sb4)}>
            " This is the best Realtor you can have. Diana went out of her way
            to satisfy her customer. Diana sold me a brand new house and also
            was able to sell my old house. I would definitely recommend her.
            ———Also, a friend, a good hearted person u can trust. "
            <div>-Jennifer Gabaldon</div>
          </div>

          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Diana McCann has been a great realtor since l have been doing
            business with her, she has provided us with unique information about
            anything you want to know about how to buy a property (house) and l
            recommend her 100% for any real state assistance, she is always
            willing to help you and assist you all the time. She is one of the
            best realtor in El Paso, Texas."
            <div>-Luis Pizana Jr.</div>
          </div>
          <div className={clsx(classes.speechBubble, classes.sb4)}>
            "Diana has been our realtor for several years, and we have always
            had a positive experience! You can count on her to walk you through
            all the steps in purchasing or selling a home. Diana is honest and
            definitely has your best interest in mind. She is responsive on all
            communication and follows up promptly on questions. We would not
            think twice about working with Diana"
            <div>-C.M. and T.H.</div>
          </div>
        </Carousel>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Box style={{ backgroundColor: "#B8CCD9" }} className={classes.box}>
          <div className={classes.formTitle}>{t("InTouch.1")}</div>
          <p className={classes.formSubtitle}>{t("NoSpam.1")}</p>
          <Grid item>
            <form
              id="contact-form"
              className={classes.contactForm}
              onSubmit={handleSubmit}
            >
              <h4 className={classes.contactFormSubheaders}> {t("Name.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="name"
                  label="Name"
                  name="userName"
                  className={classes.inputField}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}>{t("Email.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="email"
                  label="Email"
                  name="email"
                  className={classes.inputField}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}>{t("Phone.1")}</h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="phone"
                  label="Phone"
                  name="phone"
                  className={classes.inputField}
                  style={{ width: "20rem !important" }}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <h4 className={classes.contactFormSubheaders}>
                {" "}
                {t("Message.1")}
              </h4>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  id="message"
                  label="Message"
                  name="message"
                  className={classes.messageInputField}
                  onChange={handleChange}
                  margin="normal"
                  multiline="true"
                  rowsMax="4"
                />
              </Grid>
              <Grid
                container
                direction="row"
                spacing={0}
                style={{ marginTop: 20 }}
              >
                <Grid
                  container
                  spacing={0}
                  // direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    className={classes.formButton}
                    type="reset"
                    variant="contained"
                    color="primary"
                    //disabled={submitting || pristine}
                  >
                    {t("Reset.1")}
                  </Button>

                  <Button
                    className={classes.formButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t("Send.1")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {/* </div> */}
        </Box>
      </Grid>
      <div style={{ marginBottom: "100px", height: "200px" }}></div>
    </React.Fragment>
  );
};

export default Home;
