import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Card, Grid } from "@material-ui/core";
import userImage from "../../assets/user.jpg";
import { LanguageContext } from "../../contexts/language";

const useStyles = makeStyles((theme) => ({
  media: {
    height: "300px",
    backgroundPosition: "inherit",
  },
  title: {
    paddingTop: "100px",
    paddingBottom: "55px",
    color: "#144D73",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 900,
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000085",
  },
  paragraph: {
    fontSize: "20px",
    lineHeight: "2rem",
  },
  gridParent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    display: "flex",
    marginBottom: 20,
    border: "none",
    boxShadow: "none",
  },
  gridContainer: {
    paddingTop: "3rem",
    paddingBottom: "2rem",
    paddingLeft: "1rem",
    paddingRight: "5rem",
  },

  image: {
    display: "flex",
    height: "36rem",
    width: "37rem",
    backgroundColor: "#d7e0e3",
    margin: "28px",
  },
  text: {
    color: "white",
    paddingTop: "6em",
    fontFamily: "sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  topLine: {
    fontSize: "30px",
    height: ".5rem",
    paddingTop: "50px",
  },
  header: {
    color: "#144D73",
    paddingBottom: "30px",
    textTransform: "uppercase",
  },
}));

const About = () => {
  const [translationState] = useContext(LanguageContext);
  const { t, lang } = translationState;
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.title}>
        <h1 style={{ textAlign: "center" }}>{t("About.1")}</h1>
      </div>

      <body style={{ backgroundColor: "#d7e0e3" }}>
        <Grid
          container
          image={userImage}
          spacing={0}
          className={classes.gridContainer}
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <Card className={classes.card}>
              <CardMedia image={userImage} className={classes.image} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <div>
              <h1 className={classes.header}>{t("HiDiana.1")}</h1>
              <p className={classes.paragraph}> {t("HiDiana.2")}</p>
              <p className={classes.paragraph}>
                {t("HiDiana.3")}
                <br></br>
                <br></br>
                {t("HiDiana.4")}
                <br></br> <br></br> {t("HiDiana.5")}
              </p>
            </div>
          </Grid>
        </Grid>
      </body>
    </React.Fragment>
  );
};

export default About;
