import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Header from "./ui/Header.js";
import theme from "./ui/Theme";
import Home from "./Home/Home";
import About from "./About/About";
import Resources from "./Resources/Resources";
import Listings from "./Listings/Listings";
import Contact from "./Contact/Contact";
import Testimonials from "./Testimonials/Testimonials";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Header></Header>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/listings" element={<Listings />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/resources" element={<Resources />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/testimonials" element={<Testimonials />}></Route>
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
